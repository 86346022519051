import React from "react";
import { Link } from "gatsby";
import GsHero from "../components/GsHero";
import "../styles/styles.css"

// import { GatsbyImage } from 'gatsby-plugin-image';
import Image from "gatsby-plugin-sanity-image";
import { SEO } from "../components/seo";
import NavBarTwo from "../components/NavbarTwo";
import Footer from "../components/Footer";
import ContactDetails from "../components/ContactDetails";
import Topics from "../components/Topics";

const Vcf = ({ data }) => {



    return (
      <>
        <NavBarTwo />
        <section id="vcf" className="container  max-w-7xl px-5 py-24 mx-auto  mb-24 md:mb-64">
        
        <div className="mx-auto max-w-2xl text-center flex flex-col">
            <h1>Michael Kurz: Kontaktdaten im Vcf Format herunterladen:</h1>

            <a
              href="/MichaelKurz.vcf" 
              className="rounded-md bg-primary m-4 px-3.5 py-2.5 text-sm font-semibold text-gray-100 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
            >
              Outlook für Windows
            </a>
            <a
              href="/mkurz.vcf" 
              className="rounded-md bg-primary m-4 px-3.5 py-2.5 text-sm font-semibold text-gray-100 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Smartphone
            </a>
        </div>
        <br/>
        <br/>
        <div className="mx-auto max-w-2xl text-center flex flex-col">
            <h1>Markus Maier: Kontaktdaten im Vcf Format herunterladen:</h1>

            <a
              href="/Markus_Maier.vcf" 
              className="rounded-md bg-primary m-4 px-3.5 py-2.5 text-sm font-semibold text-gray-100 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
            >
              Outlook für Windows
            </a>
            <a
              href="/vcard_maier.vcf" 
              className="rounded-md bg-primary m-4 px-3.5 py-2.5 text-sm font-semibold text-gray-100 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Smartphone
            </a>
        </div>
       
        </section>












        <Footer />
    </>
     
    )
}

export default Vcf;

